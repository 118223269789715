import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageableUtilService } from '@services/util';
import { CustomerAccount, CustomerAccountFilterParams, PageableData, TableParams } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerAccountService {

  private readonly API_URL = '/api/users/customer';

  constructor(
    private readonly httpClient: HttpClient,
    private readonly util: PageableUtilService
  ) { }

  public list(tableParams: TableParams, filters?: CustomerAccountFilterParams): Observable<PageableData<CustomerAccount>> {
    const params = this.util.buildParams(tableParams, filters);
    return this.httpClient.get<PageableData<CustomerAccount>>(`${this.API_URL}`, { params });
  }

  public get(code: string): Observable<CustomerAccount> {
    return this.httpClient.get<CustomerAccount>(`${this.API_URL}/${code}`);
  }

  public getMe(): Observable<CustomerAccount> {
    return this.httpClient.get<CustomerAccount>(`${this.API_URL}/me`);
  }

  public delete(code: string): Observable<CustomerAccount> {
    return this.httpClient.delete<CustomerAccount>(`${this.API_URL}/${code}`);
  }

  public create(user: CustomerAccount | Partial<CustomerAccount>): Observable<CustomerAccount> {
    if (user?.code) {
      return this.httpClient.patch<CustomerAccount>(`${this.API_URL}/${user.code}`, user);
    } else {
      return this.httpClient.post<CustomerAccount>(this.API_URL, user);
    }
  }

  public patch(code: string, payload: Partial<CustomerAccount>): Observable<CustomerAccount> {
    return this.httpClient.patch<CustomerAccount>(`${this.API_URL}/${code}`, payload);
  }

}
